import React from 'react'
import PropTypes from 'prop-types'

const icons = {
  bag: {
    shape: (
      <path d="m432 79l-96 0 0-30c0-27-21-48-48-48l-63 0c-27 0-48 21-48 48l0 30-97 0c-18 0-32 15-32 32l0 368c0 18 14 32 32 32l352 0c18 0 32-14 32-32l0-368c0-17-14-32-32-32z m-223-30c0-9 7-16 16-16l63 0c9 0 16 7 16 16l0 30-95 0z m223 430l-352 0 0-368 97 0 0 33c0 0-1 16 15 16 18 0 17-16 17-16l0-33 95 0 0 33c0 0-1 16 16 16 16 0 16-16 16-16l0-33 96 0z"/>
    )
  },
  graduation: {
    shape: (
      <path d="m495 348l0-129 8-4c6-3 9-8 9-14 0-6-3-12-8-14l-241-129c-5-3-11-3-15 0l-240 129c-5 3-8 8-8 14 0 6 3 11 8 14l106 56c-2 2-3 5-3 8l0 135c0 5 2 9 6 12 3 3 36 30 138 30 101 0 136-25 139-28 4-3 6-8 6-13l0-139c0-2 0-4-1-6l64-34 0 112c-9 6-15 16-15 28 0 17 14 32 32 32 17 0 32-15 32-32 0-12-7-22-17-28z m-127 58c-13 6-46 18-113 18-67 0-100-13-112-19l0-118 104 55c3 1 5 2 8 2 2 0 5-1 7-2l106-56 0 120z m-113-96l-206-109 206-111 207 110z"/>
    )
  },
  facebook: {
    shape: (
      <path d="m291 40c2 0 3 0 3 0l42 0-1 48-41 0c-23 0-22 20-22 20l0 76 80 0-11 48-70 0 0 240-48 0 0-240-63 0 0-48 63 0 0-75c0-65 54-69 68-69z m45 0z m-45-32c-12 0-34 2-55 15-21 12-45 37-45 86l0 43-31 0c-9 0-17 3-23 9-6 6-9 14-9 23l0 48c0 18 14 32 32 32l31 0 0 208c0 17 15 32 32 32l48 0c18 0 32-15 32-32l0-208 38 0c14 0 27-10 31-23l11-48c3-10 1-20-5-28-6-8-15-13-26-13l-48 0 0-32 31 0c18 0 32-14 32-32l1-46c0 0 0-1 0-2 0-17-15-32-32-32l-41 0c-1 0-2 0-4 0z"/>
    )
  },
  google: {
    shape: (
      <path d="m354 180c-36-38-64-44-92-44l-2 0c-63 0-122 52-122 124 0 76 68 120 122 120l0 0c30 0 67-6 99-52l-123 0 0-128 261 2c3 13 7 45 7 62 0 144-97 248-246 248-142 0-258-114-258-254 0-141 116-254 258-254 70 0 144 28 191 90z m-94 46l0 78 132 0c-6 32-41 97-132 97-80 0-144-65-144-142 0-78 66-144 144-144 46 0 76 20 92 36l64-59c-40-38-93-60-156-60-128 0-232 100-232 227 0 125 104 226 232 226 135 0 222-92 222-221 0-14-1-26-3-38l-219 0z"/>
    )
  },
  github: {
    shape: (
      <path d="m256 2c-140 0-254 114-254 255 0 140 114 254 254 254 140 0 254-114 254-254 0-141-114-255-254-255z m0 22c128 0 232 104 232 233 0 1 0 1 0 2-6-1-15-1-25-1-15 0-33 0-54 1 1-6 1-11 1-17 1-21-6-40-18-57 2-10 6-21 7-36 1-15 0-33-14-44l-3-3-4 0c-30 0-50 12-62 22-18-7-38-11-61-11-22 0-43 4-62 11-12-10-33-22-62-22l-4 0-4 3c-12 11-13 28-12 43 1 13 3 27 7 36-11 17-18 37-18 57 0 6 0 11 1 17-20-1-37-1-52-1-10 0-19 1-25 1 0-1 0-1 0-3 0-127 104-231 232-231z m-117 101c22 2 38 12 46 19l6 4 6-2c18-8 37-11 59-11 22 0 41 4 58 11l6 3 6-4c7-7 24-17 46-19 3 4 6 12 5 22-2 12-5 27-7 34l-2 6 3 4c11 15 17 31 15 49 0 28-9 50-30 66-21 17-55 27-102 27-48 0-81-10-102-27-21-16-31-38-31-66 0-17 6-34 17-49l3-4-1-6c-2-8-6-23-7-35 0-11 2-18 6-22z m-61 143c8 0 17 0 25 1 2 3 3 6 4 10-37 1-64 4-81 7-1-5-1-11-1-16 11-1 29-2 53-2z m356 0c23 0 41 1 53 2 0 5-1 11-1 16-18-3-45-6-83-7 1-4 2-7 3-10 10 0 19-1 28-1z m-323 22c7 13 16 25 29 34 16 13 39 22 66 28-3 3-6 7-10 12l-1-1c0 0-5 2-13 3-9 1-19 0-28 0-13 0-20-7-28-18-6-6-13-13-18-17-7-5-12-8-19-9l-2 0c-6 0-10 0-15 5-2 3-4 8-3 12 1 5 3 7 6 9 15 10 17 30 27 48 9 18 28 25 48 25l29 0 0 55c-78-28-136-96-152-179 17-3 45-7 84-7z m288 0c39 0 68 4 86 7-14 83-73 151-152 179l0-58c0-18-5-36-15-53-3-4-7-10-11-13 25-6 46-15 63-27 12-10 22-22 29-35z m-157 67l31 0c8 0 18 7 26 20 8 13 12 29 12 41l0 64c-17 5-36 7-55 7-19 0-38-2-55-7l0-64c0-12 5-29 13-41 8-13 19-20 28-20z m-126 12c10 10 21 19 38 19 9 0 20 0 30-1-1 4-2 8-3 12l-31 0c-13 0-23-4-28-13-2-3-4-10-6-17z"/>
    )
  },
  instagram: {
    shape: (
      <path d="m256 2c69 0 77 0 104 2 26 1 45 5 62 12 17 6 31 15 45 29 14 14 22 28 29 45 6 16 11 35 12 61 1 27 1 36 1 105 0 69 0 77-1 104-1 27-6 45-12 62-7 17-15 31-29 45-14 14-28 22-45 29-17 6-36 11-62 12-27 1-36 1-104 1-69 0-78 0-105-1-26-1-45-6-61-12-18-7-32-15-46-29-14-14-22-28-29-45-6-17-11-35-12-62-1-27-1-35-1-104 0-69 0-78 1-105 1-26 6-45 12-61 7-17 15-31 29-45 14-14 28-23 46-29 16-7 35-12 61-12 27-2 36-2 105-2z m0 33c-72 0-81 1-109 2-26 1-40 6-50 9-24 10-41 27-51 51-4 10-8 24-10 50-1 28-1 37-1 109 0 72 0 80 1 108 2 26 6 41 10 50 10 25 26 41 51 51 10 4 24 9 50 10 28 1 37 1 109 1 72 0 80 0 108-1 26-1 40-6 50-10 24-10 41-26 51-51 4-9 9-24 10-50 1-28 1-36 1-108 0-72 0-81-1-109-1-26-6-40-10-50-10-24-26-41-51-51-10-3-24-8-50-9-28-1-36-2-108-2z m0 322c56 0 101-45 101-101 0-56-45-102-101-102-56 0-102 46-102 102 0 56 46 101 102 101z m0-231c72 0 130 58 130 130 0 72-58 130-130 130-72 0-130-58-130-130 0-72 58-130 130-130z m166-6c0 18-14 30-32 30-17 0-30-13-30-30 0-16 14-31 30-31 17 0 32 14 32 31z"/>
    )
  },
  linkedin: {
    shape: (
      <path d="m499 501l-104 0c-7 0-13-6-13-13l0-168c0-57-30-57-40-57-26 0-37 21-41 30-2 4-2 11-2 20l0 175c0 7-6 13-14 13l-104 0c-3 0-7-1-9-4-3-2-4-6-4-9 0-3 1-284 0-313 0-3 1-7 3-9 3-3 6-4 10-4l104 0c8 0 14 6 14 13l0 8c17-16 42-29 80-29 83 0 133 58 133 154l0 180c0 7-6 13-13 13z m-91-26l78 0 0-167c0-81-39-127-107-127-45 0-67 22-81 43-1 5-7 10-13 10l0 0c-5 0-10-3-12-7-2-4-2-7-1-11l0-28-77 0c0 56 0 235 0 286l77 0 0-161c0-13 1-23 4-30 12-29 37-47 66-47 42 0 66 31 66 84l0 155z m-284 26l-105 0c-7 0-13-6-13-13l0-313c0-7 6-13 13-13l105 0c7 0 13 6 13 13l0 313c0 7-6 13-13 13z m-91-26l77 0 0-287-77 0z m39-330l-1 0c-41 0-71-28-71-67 0-39 30-68 72-68 41 0 71 28 72 68 0 39-31 67-72 67z m0-108c-27 0-45 17-45 41 0 24 18 41 44 41l1 0c27 0 45-17 45-41 0-25-18-41-45-41z"/>
    )
  },
  twitter: {
    shape: (
      <path d="m342 79c27 0 50 11 67 29 21-5 41-12 59-22-7 21-22 38-41 49 19-2 37-7 53-14-12 18-28 34-46 46 0 4 0 8 0 12 0 118-92 254-261 254-52 0-100-15-141-40 7 0 14 1 22 1 43 0 83-14 114-38-40-1-74-27-86-62 6 1 11 1 17 1 9 0 17-1 25-3-42-8-74-44-74-87l0-1c12 6 26 10 42 11-25-16-41-44-41-75 0-16 4-31 12-44 45 54 113 89 190 93-2-7-3-14-3-20 0-50 41-90 92-90z m138 42z m-138-74c-63 0-115 46-123 106-51-11-98-38-131-78-7-7-16-11-25-11-1 0-2 0-2 0-11 1-20 6-25 15-12 19-17 40-17 61 0 15 2 29 7 42-5 6-8 14-8 22l0 1c0 31 12 60 33 82-1 5-1 11 1 17 7 21 20 40 36 54-11 3-22 4-34 4-7 0-13-1-18-1-2 0-3 0-4 0-14 0-26 8-30 21-5 14 0 30 13 38 47 29 102 45 158 45 182 0 291-143 293-282 15-12 28-27 40-43 4-5 6-12 6-19 0-11-5-20-14-26 4-12 0-26-10-34-6-5-13-7-20-7-6 0-11 1-16 4-11 6-22 11-34 15-22-17-48-26-76-26z"/>
    )
  },
}

const SVG = ({ icon }) => {
  return (
    <svg viewBox="0 0 512 512">
      {icons[icon].shape}
    </svg>
  )
}

SVG.propTypes = {
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
}

export default SVG
export { SVG }
